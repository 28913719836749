import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

// Components
import {
  TextInput,
  utils,
} from 'ui-library-unlocker';
import FormInfoRequired from '../../../components/atoms/FormInfoRequired/FormInfoRequired';

// Hooks
import useResponsive from '../../../hooks/useResponsive';

// Utils
import { displayError, isFieldValid } from '../../../utils/forms/form';

// Styles
import styles from './InventoryKeys.module.scss';

function InventoryKeys() {
  const { t } = useTranslation();
  const { formik, updateInventoryMutation, isReadOnly } = useOutletContext();
  const { isMobile } = useResponsive();

  const handleBlur = useCallback((e) => {
    formik.handleBlur(e);
    if (formik.values.uid) {
      updateInventoryMutation.mutate(formik.values);
    }
  }, [formik, updateInventoryMutation]);

  return (
    <form
      id="inventory-keys"
      className={styles.form}
    >
      {isMobile && (
        <h2 className={utils.cn([styles.title, 'm-b-25'])}>
          {t('inventory.crud.menu.keys')}
        </h2>
      )}
      <FormInfoRequired />
      <TextInput
        className="m-t-25"
        label={t('inventory.crud.keys.entryKeys')}
        type="number"
        min="0"
        id="entryKeys"
        name="entryKeys"
        value={formik.values.entryKeys}
        // so 0 is not considered empty
        onChange={(event) => formik.setFieldValue('entryKeys', parseInt(event.target.value, 10))}
        onBlur={handleBlur}
        error={displayError(t, formik, 'entryKeys')}
        valid={isFieldValid(formik, 'entryKeys')}
        disabled={isReadOnly}
      />
      <TextInput
        className="m-t-25"
        label={t('inventory.crud.keys.buildingKeys')}
        type="number"
        min="0"
        id="buildingKeys"
        name="buildingKeys"
        value={formik.values.buildingKeys}
        onChange={(event) => formik.setFieldValue('buildingKeys', parseInt(event.target.value, 10))}
        onBlur={handleBlur}
        error={displayError(t, formik, 'buildingKeys')}
        valid={isFieldValid(formik, 'buildingKeys')}
        disabled={isReadOnly}
      />
      <TextInput
        className="m-t-25"
        label={t('inventory.crud.keys.gateKeys')}
        type="number"
        min="0"
        id="gateKeys"
        name="gateKeys"
        value={formik.values.gateKeys}
        onChange={(event) => formik.setFieldValue('gateKeys', parseInt(event.target.value, 10))}
        onBlur={handleBlur}
        error={displayError(t, formik, 'gateKeys')}
        valid={isFieldValid(formik, 'gateKeys')}
        disabled={isReadOnly}
      />
      <TextInput
        className="m-t-25"
        label={t('inventory.crud.keys.garageKeys')}
        type="number"
        min="0"
        id="garageKeys"
        name="garageKeys"
        value={formik.values.garageKeys}
        onChange={(event) => formik.setFieldValue('garageKeys', parseInt(event.target.value, 10))}
        onBlur={handleBlur}
        error={displayError(t, formik, 'garageKeys')}
        valid={isFieldValid(formik, 'garageKeys')}
        disabled={isReadOnly}
      />
      <TextInput
        className="m-t-25"
        label={t('inventory.crud.keys.postalBoxKeys')}
        type="number"
        min="0"
        id="postalBoxKeys"
        name="postalBoxKeys"
        value={formik.values.postalBoxKeys}
        onChange={(event) => formik.setFieldValue('postalBoxKeys', parseInt(event.target.value, 10))}
        onBlur={handleBlur}
        error={displayError(t, formik, 'postalBoxKeys')}
        valid={isFieldValid(formik, 'postalBoxKeys')}
        disabled={isReadOnly}
      />
    </form>
  );
}

export default InventoryKeys;
