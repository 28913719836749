/* eslint-disable react/no-children-prop */
import React, {
  useEffect, useCallback, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Outlet,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Components
import {
  WithHeader, Picto, utils, Button,
} from 'ui-library-unlocker';
import NotificationCenter from '../components/templates/NotificationCenter/NotificationCenter';

// Views
import Menu from '../components/templates/Menu/Menu';
import NotFound from '../views/404/404';
import ProfileNeedCompletion from '../views/ProfileNeedCompletion/ProfileNeedCompletion';
import MaintenanceView from '../views/Maintenance/Maintenance';
import ErrorView from '../views/500/500';

// Utils
import { withHeaderMenuRoutes, loginRoutes, publicRoutes } from './routesConfig';
import { useAppContext } from '../store/context';

// Hooks
import useRoles from '../hooks/useRoles';

// Constants
import { ENROLMENT_STATUS } from '../utils/constants';

import styles from './Routes.module.scss';

function RenderWithHeader({
  accessToken, sideMenuOpen, dispatch, t, notifFlag,
}) {
  const navigate = useNavigate();
  const { context: { me, hasCompletedOnboardingFunnel, skipOnboarding } } = useAppContext();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { isUserAdmin } = useRoles();

  const impersonated = JSON.parse(localStorage.getItem('impersonateIdentity'));

  const handleUnimpersonate = () => {
    localStorage.setItem('accessToken', localStorage.getItem('accessTokenBackup'));
    localStorage.setItem('refreshToken', localStorage.getItem('refreshTokenBackup'));
    localStorage.removeItem('accessTokenBackup');
    localStorage.removeItem('refreshTokenBackup');
    localStorage.removeItem('impersonateIdentity');
    localStorage.setItem('hasCompletedOnboardingFunnel', localStorage.getItem('hasCompletedOnboardingFunnelBackup'));
    localStorage.removeItem('hasCompletedOnboardingFunnelBackup');
    localStorage.setItem('hasRealEstateAgency', localStorage.getItem('hasRealEstateAgencyBackup'));
    localStorage.removeItem('hasRealEstateAgencyBackup');
    localStorage.removeItem('roleSpace');
    // eslint-disable-next-line no-restricted-globals
    location.replace('/');
  };

  const handleLogout = useCallback(() => {
    dispatch({ type: 'LOGOUT_USER' });
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessTokenBackup');
    localStorage.removeItem('refreshTokenBackup');
    localStorage.removeItem('hasCompletedOnboardingFunnel');
    localStorage.removeItem('hasCompletedOnboardingFunnelBackup');
    localStorage.removeItem('hasRealEstateAgency');
    localStorage.removeItem('hasRealEstateAgencyBackup');
    localStorage.removeItem('roleSpace');
  }, [dispatch]);

  const renderNav = () => (
    <nav className={styles.nav}>
      <ul className={styles.list}>
        {(hasCompletedOnboardingFunnel || skipOnboarding || isUserAdmin) && (
        <>
          {notifFlag && (
            <li className={utils.cn([styles.item, styles.itemIcon, styles.notificationIcon, 'm-l-25'])}>
              <NotificationCenter
                iconWidth={24}
              />
            </li>
          )}
          <li className={utils.cn([styles.item, styles.itemIcon, styles.messageIcon, 'm-l-25'])}>
            <Picto
              onClick={() => navigate('/messages')}
              color="var(--color-white)"
              width={24}
              icon="mail"
            />
            {me?.unreadMessages ? <div className={styles.unread} /> : null}
          </li>
        </>
        )}
        {hasCompletedOnboardingFunnel === false && !skipOnboarding && !isUserAdmin && (
          <li className={styles.item}>
            <Button
              onClick={impersonated ? handleUnimpersonate : handleLogout}
              label={t('global.logout')}
              size={isMobile ? 'small' : 'medium'}
            />
          </li>
        )}
      </ul>
    </nav>
  );

  const shoudlDisplayBurger = useMemo(() => {
    if (isUserAdmin) return true;
    if (skipOnboarding) return true;
    if (hasCompletedOnboardingFunnel) return true;
    return false;
  }, [isUserAdmin, hasCompletedOnboardingFunnel, skipOnboarding]);

  return (
    <WithHeader
      logoLink={accessToken ? '/' : 'register'}
      navContent={renderNav()}
      isContained={false}
      className="p-l-20 p-r-20"
      sideMenuOpen={sideMenuOpen}
      onMenuToggle={() => {
        dispatch({ type: 'SET_SIDEMENU_OPEN', payload: !sideMenuOpen });
      }}
      displayBurger={shoudlDisplayBurger}
    >
      <Menu>
        <Outlet />
      </Menu>
    </WithHeader>
  );
}
RenderWithHeader.propTypes = {
  accessToken: PropTypes.string,
  t: PropTypes.func.isRequired,
  sideMenuOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  notifFlag: PropTypes.bool,
};

RenderWithHeader.defaultProps = {
  accessToken: null,
  notifFlag: false,
};

function AppRoutes() {
  const {
    context: {
      accessToken,
      sideMenuOpen,
      me,
      accessTokenBackup,
    }, dispatch,
  } = useAppContext();
  const { t } = useTranslation();
  const {
    isUserAdmin,
    isUserLessor,
    isUserTenant,
    isUserRealEstateManager,
  } = useRoles();
  const queryClient = useQueryClient();
  const {
    maintenance,
    notifications: notifFlag,
    paymentpage,
    rentDistribution,
    inventoryPage,
  } = useFlags();
  const toastId = useRef(null);

  useEffect(() => {
    if (!accessToken) queryClient.clear();
  }, [accessToken]);

  useEffect(() => {
    if (accessTokenBackup) {
      toastId.current = utils.toast.info(t('global.impersonatingMsg'), {
        autoClose: false,
        position: 'bottom-right',
        closeButton: true,
        closeOnClick: false,
        draggable: true,
      });
    } else {
      utils.toast.dismiss(toastId.current);
    }
  }, [accessTokenBackup]);

  if (maintenance) return <MaintenanceView />;

  const shouldFillProfile = (route) => (
    route?.metadata?.needProfileCompleted
    && ![ENROLMENT_STATUS.COMPLETED, ENROLMENT_STATUS.ENROLMENT_BYPASSED].includes(me?.onboardingStatus)
  )
    || (route?.metadata?.needProfileFilled && me?.onboardingStatus === ENROLMENT_STATUS.PENDING);

  const renderWithHeaderMenuRoutes = withHeaderMenuRoutes.map((route) => {
    const getElement = () => {
      if (!accessToken) return <Navigate to="/register" />;

      const killswitches = {
        paymentpage,
        rentDistribution,
        inventoryPage,
      };
      if (route.killswitch && !killswitches[route.killswitch]) return <NotFound />;

      if (
        !isUserAdmin
        && (route.hideForLessor && isUserLessor)
        && !(route.showForManager && isUserRealEstateManager)
      ) {
        return <NotFound />;
      }

      if (route.hideForTenant && isUserTenant) return <NotFound />;

      if (shouldFillProfile(route) && !isUserAdmin && !isUserTenant) {
        return (
          <ProfileNeedCompletion
            title={t(route?.metadata?.needProfileTitle)}
            heading={t(route?.metadata?.needProfileHeading)}
          />
        );
      }
      return route.element;
    };

    return (
      <Route
        key={route.label}
        element={getElement()}
        errorElement={<ErrorView />}
        {...route.routeProps}
      >
        {route.nestedRoutes ? route.nestedRoutes.map((nestedRoute) => (
          <Route
            key={nestedRoute.label}
            element={nestedRoute.element}
            errorElement={<ErrorView />}
            {...nestedRoute.routeProps}
          />
        )) : null}
      </Route>
    );
  });

  const renderLoginRoutes = loginRoutes.map((route) => (
    <Route
      key={route.label}
      element={accessToken ? <Navigate to="/" /> : route.element}
      errorElement={<ErrorView />}
      {...route.routeProps}
    />
  ));

  const renderPublicRoutes = publicRoutes.map((route) => (
    <Route
      key={route.label}
      element={route.element}
      errorElement={<ErrorView />}
      {...route.routeProps}
    >
      {route.nestedRoutes ? route.nestedRoutes.map((nestedRoute) => (
        <Route
          key={nestedRoute.label}
          errorElement={<ErrorView />}
          {...nestedRoute.routeProps}
        />
      )) : null}
    </Route>
  ));

  const router = createBrowserRouter(createRoutesFromElements(
    <>
      {renderPublicRoutes}
      {renderLoginRoutes}
      <Route
        path="/"
        errorElement={<ErrorView />}
        element={(
          <RenderWithHeader
            accessToken={accessToken}
            t={t}
            sideMenuOpen={sideMenuOpen}
            dispatch={dispatch}
            notifFlag={notifFlag}
          />
        )}
      >
        {renderWithHeaderMenuRoutes}
        <Route
          path="*"
          element={<NotFound />}
          errorElement={<ErrorView />}
        />
      </Route>
    </>,
  ));

  return (<RouterProvider router={router} />);
}

export default AppRoutes;
