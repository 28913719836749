/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import { centsToEuro } from 'ui-library-unlocker/src/utils/money';

// Components
import {
  Tag,
  Picto,
  utils,
} from 'ui-library-unlocker';
import DataTable from '../../../components/organisms/DataTable/DataTable';
import MaskedField from '../../../components/atoms/MaskedField/MaskedField';
import Tooltip from '../../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../../components/atoms/TableOptions/TableOptions';

// Services
import { getUserPayments } from '../../../services/payment';
import { getConnectedTenantLeases, getLeaseIBAN } from '../../../services/lease';

// Hooks
import useFilters from '../../../hooks/useFilters';

// Utils
import { formatAddress } from '../../../utils/properties';
import { getPaymentAndAccountStatusVariant } from '../../../utils/variants';

// Constants
import { PAYMENT_AND_ACCOUNT_STATUS_LIST } from '../../../utils/constants';

import styles from './PaymentsList.module.scss';

const STATUS_FILTERS = [
  PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULED,
  PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULED_MANUAL,
  PAYMENT_AND_ACCOUNT_STATUS_LIST.NOTIFIED,
  PAYMENT_AND_ACCOUNT_STATUS_LIST.PENDING,
  PAYMENT_AND_ACCOUNT_STATUS_LIST.EXPORTED,
];

function PaymentsList() {
  const { t } = useTranslation();
  const { filters } = useFilters();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  const leases = useMemo(() => {
    if (!data) return [];
    return [...new Set(data.map((p) => p.lease))];
  }, [data]);

  const formatData = useCallback((rawData) => {
    if (!rawData || !leases) return [];
    return [...leases].map((l) => [...rawData].filter((p) => p.lease === l)).flat();
  }, [leases]);

  const ibanMutation = useMutation({
    mutationFn: getLeaseIBAN,
    onSuccess: () => {
      utils.toast.info(t('lease.table.ibanExportSuccess'));
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const {
    data: leaseListData,
    isFetching: leaseListFetching,
  } = useQuery({
    queryKey: ['payment-lease-list', leases],
    queryFn: () => getConnectedTenantLeases({
      filters: {
        uid: leases,
      },
    }),
    keepPreviousData: true,
    enabled: !!leases.length > 0,
  });

  const columns = useMemo(() => ([
    {
      header: t('payment.table.columns.property'),
      accessorKey: 'propertyName',
      size: 250,
      cell: ({ row: { original: { lease } } }) => {
        const {
          propertyName,
          propertyAddress,
        } = leaseListData?.data?.collection?.find((l) => l.uid === lease) || {};
        return (
          <div>
            <div className={styles.nameAddress}>
              <p className="p-2-700">
                {propertyName}
              </p>
              <p className="p-2-500">
                {formatAddress(propertyAddress)}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      header: t('payment.table.columns.date'),
      accessorKey: 'date',
      size: 150,
      enableSorting: false,
      cell: ({ row: { original: { month, year } } }) => (
        <div>
          <span>{month && year ? `${t(`global.months.${month}`)} ${year}` : '-'}</span>
        </div>
      ),
    },
    {
      header: t('payment.table.columns.paymentMethod'),
      accessorKey: 'paymentMethod',
      size: 150,
      enableSorting: false,
      cell: ({ row: { original: { paymentMethod } } }) => (
        <span>
          {t(`global.paymentMethods.${paymentMethod}`)}
        </span>
      ),
    },
    {
      header: t('payment.table.columns.amount'),
      accessorKey: 'amount',
      size: 150,
      cell: ({ row: { original: { amount } } }) => (
        <div>
          <span>{amount != null ? `${centsToEuro(amount)} €` : '-'}</span>
        </div>
      ),
    },
    {
      header: t('payment.table.columns.iban'),
      accessorKey: 'iban',
      size: 200,
      enableSorting: false,
      cell: ({ row: { original: { iban } } }) => (
        <div>
          <span>
            {iban ? (
              <MaskedField
                value={iban}
                className={styles.maskedIban}
                lastCharsVisibleLength={3}
                formatValue={(val) => val.replace(/(.{4})/g, '$1 ')}
              />
            ) : '-'}
          </span>
        </div>
      ),
    },
    {
      header: t('payment.table.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <div>
          <Tag
            label={t(`payment.table.status.${status}`)}
            size="medium"
            variant={getPaymentAndAccountStatusVariant(status)}
          />
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 50,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          <Picto
            id={`more-option-payment-${row?.original?.uid}`}
            icon="more"
            width={24}
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
            }}
            color="var(--color-secondary)"
          />
          <Tooltip
            isOpen={tooltipOpen === row?.original?.uid}
            anchorId={`more-option-payment-${row?.original?.uid}`}
            place="bottom"
            type="dark"
            effect="solid"
          >
            <TableOptions
              options={[
                // {
                //   id: 'registerPayment',
                //   label: t('payment.table.registerPayment'),
                //   icon: <Picto icon="receipt-2" width={24} color="var(--color-primary)" />,
                //   onHoverIcon: <Picto icon="receipt-2" width={24} color="var(--color-white)" />,
                //   onClick: (e) => {
                //     e.stopPropagation();
                //     setTooltipOpen(null);
                //     // open modal
                //   },
                // },
                {
                  id: 'iban',
                  label: t('global.listOptions.downloadIBAN'),
                  icon: <Picto icon="import" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="import" width={24} color="var(--color-white)" />,
                  onClick: (e) => {
                    e.stopPropagation();
                    setTooltipOpen(null);
                    ibanMutation.mutate(row?.original?.lease);
                  },
                },
              ]}
            />
          </Tooltip>
        </div>
      ),
    },
  ]), [t, tooltipOpen, leaseListData, ibanMutation, leases]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'status',
      type: 'checkbox',
      title: t('payment.table.columns.status'),
      options: [
        {
          value: `${PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULED},${PAYMENT_AND_ACCOUNT_STATUS_LIST.EXPORTED}`,
          label: t(`payment.table.status.${PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULED}`),
        },
        {
          value: PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULED_MANUAL,
          label: t(`payment.table.status.${PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULED_MANUAL}`),
        },
        {
          value: PAYMENT_AND_ACCOUNT_STATUS_LIST.NOTIFIED,
          label: t(`payment.table.status.${PAYMENT_AND_ACCOUNT_STATUS_LIST.NOTIFIED}`),
        },
        {
          value: PAYMENT_AND_ACCOUNT_STATUS_LIST.PENDING,
          label: t(`payment.table.status.${PAYMENT_AND_ACCOUNT_STATUS_LIST.PENDING}`),
        },
      ],
    },
  ], []);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={null}
      onClick={() => setTooltipOpen(null)}
    >
      <DataTable
        columns={columns}
        searchLabel={t('payment.search')}
        getData={getUserPayments}
        queryKey="payment-list"
        allFiltersOptions={allFiltersOptions}
        setData={(value) => setData(value)}
        setDataLoading={(value) => setDataLoading(value)}
        loading={leaseListFetching || dataLoading}
        forcedFilters={{
          status: filters?.status?.length > 0 ? filters?.status : STATUS_FILTERS,
        }}
        formatData={formatData}
      />
    </div>
  );
}

export default PaymentsList;
