import { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'lodash';

// Components
import {
  DateInput,
  SelectInput,
  Message,
  utils,
  Tag,
} from 'ui-library-unlocker';
import FormInfoRequired from '../../../components/atoms/FormInfoRequired/FormInfoRequired';
import InventoryMeters from '../InventoryMeters/InventoryMeters';
import InventoryKeys from '../InventoryKeys/InventoryKeys';
import InventoryRooms from '../InventoryRooms/InventoryRooms';
import InventorySigners from '../InventorySigners/InventorySigners';

// Hooks
import useResponsive from '../../../hooks/useResponsive';
import useRoles from '../../../hooks/useRoles';
import { useAppContext } from '../../../store/context';

// Utils
import { displayError, isFieldValid } from '../../../utils/forms/form';
import { formatAddress } from '../../../utils/properties';

// Styles
import styles from './InventoryGeneral.module.scss';

function InventoryGeneral() {
  const { t } = useTranslation();
  const {
    isReadOnly,
    formik,
    leasesListData,
    setLeaseQuery,
    leasesListFetching,
    propertiesListData,
    propertiesListFetching,
    setPropertyQuery,
    createInventoryMutation,
    updateInventoryMutation,
    collapsedFurniture,
    status,
  } = useOutletContext();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { id } = useParams();
  const { isUserAdmin } = useRoles();
  const { context: { user, me } } = useAppContext();

  const handleChange = useCallback((field, value) => {
    formik.setFieldValue(field, value);
    if (formik.values.uid) {
      updateInventoryMutation.mutate({
        ...formik.values,
        [field]: value,
      });
    }
  }, [formik]);

  const propertyOptions = useMemo(() => {
    if (!propertiesListData?.data?.collection) return [];
    return propertiesListData.data.collection.map((property) => ({
      value: property.uid,
      label: property.name,
      address: property.address,
    }));
  }, [propertiesListData]);

  const handleSearchProperty = useCallback(debounce((value) => {
    setPropertyQuery(value);
  }, 500), [setPropertyQuery]);

  const handlePropertyChange = useCallback((property) => {
    const { value, label, address } = property;
    formik.setFieldValue('propertyUid', value);
    formik.setFieldValue('property', {
      name: label,
      address,
    });
    formik.setFieldValue('leaseUid', null);
    formik.setFieldValue('leaseName', null);
    formik.setFieldValue('signers', []);
    const newValues = {
      ...formik.values,
      propertyUid: value,
      property: {
        name: label,
        address,
      },
      leaseUid: null,
      leaseName: null,
      signers: [],
    };
    if (!createInventoryMutation.isLoading && !updateInventoryMutation.isLoading) {
      (formik.values.uid ? updateInventoryMutation : createInventoryMutation).mutate(newValues);
    }
  }, [formik, updateInventoryMutation, createInventoryMutation]);

  const getUserHasLeaseManagementRights = useCallback((managerUid) => {
    if (isUserAdmin) return true;
    if (!managerUid || !user?.username || !me?.aclMatrix?.companies) return false;
    if (user.username === managerUid) return true;
    if (me.aclMatrix.companies
      .filter((company) => company.owner || (!company.owner && !company.isRealEstateAgency))
      .some((company) => company.uid === managerUid)) return true;
    return false;
  }, [user, isUserAdmin, me]);

  const leaseOptions = useMemo(() => {
    if (!leasesListData?.data?.collection) return [];
    return leasesListData.data.collection
      .filter((lease) => getUserHasLeaseManagementRights(lease.managerUid))
      .map((lease) => ({
        value: lease.uid,
        label: lease.name,
        manager: lease.manager,
        tenants: lease.tenantDetails,
      }));
  }, [leasesListData]);

  const handleSearchLease = useCallback(debounce((value) => {
    setLeaseQuery(value);
  }, 500), [setLeaseQuery]);

  const handleLeaseChange = useCallback((lease) => {
    const {
      value, label, manager, tenants,
    } = lease;
    formik.setFieldValue('leaseUid', value);
    formik.setFieldValue('leaseName', label);
    const newValues = {
      ...formik.values,
      leaseUid: value,
      leaseName: label,
    };
    if (formik.values.leaseUid !== value) {
      const newSigners = [
        {
          uid: manager?.uid,
          brandName: manager?.brandName,
          firstName: manager?.brandName ? undefined : manager?.firstName,
          lastName: manager?.brandName ? undefined : manager?.lastName,
          wasPresent: true,
          role: 'manager',
          signed: false,
        },
      ];
      tenants?.forEach((tenant) => newSigners.push({
        uid: tenant.uid,
        firstName: tenant.firstName,
        lastName: tenant.lastName,
        wasPresent: false,
        role: 'tenant',
        signed: false,
      }));
      formik.setFieldValue('signers', newSigners);
      newValues.signers = newSigners;
    }
    if (formik.values.uid) {
      updateInventoryMutation.mutate(newValues);
    }
  }, [formik, t, updateInventoryMutation]);

  const leaseValue = useMemo(() => {
    if (isReadOnly) {
      return {
        value: formik.values.lease?.uid,
        label: formik.values.lease?.name,
      };
    }
    return leaseOptions.find((item) => item.value === formik.values.leaseUid) || null;
  }, [formik.values, leaseOptions, isReadOnly]);

  useEffect(() => {
    const pagesInDOM = [];
    function handleScroll() {
      const inventoryContent = document.getElementById('inventory-edit');
      const { scrollTop, offsetHeight } = inventoryContent;
      pagesInDOM.forEach((page) => {
        const newHash = `#${page.id}`;
        const offset = offsetHeight / 2;
        if (
          scrollTop >= (page.top - offset)
          && scrollTop < (page.bottom - offset)
          && window.location.hash !== newHash
        ) {
          navigate(newHash);
        }
      });
    }
    if (isMobile) {
      const pagesIds = [
        'inventory-general',
        'inventory-meters',
        'inventory-keys',
        'inventory-signers',
      ];
      pagesIds?.forEach((pageId) => {
        const element = document.getElementById(pageId);
        if (element) {
          pagesInDOM.push({
            id: pageId,
            top: element.offsetTop,
            bottom: element.offsetTop + element.offsetHeight,
          });
        }
      });
      document.getElementById('inventory-edit')?.addEventListener('scroll', handleScroll);
    }
    return () => {
      document.getElementById('inventory-edit')?.removeEventListener('scroll', handleScroll);
    };
  }, [
    // if something changes in the rooms, it could change some divs start point
    formik.values.rooms,
    navigate,
    collapsedFurniture,
    id,
    isMobile,
  ]);

  return (
    <>
      <form
        id="inventory-general"
        className={styles.form}
      >
        {isMobile && (
        <>
          <Tag
            variant={status?.variant}
            className="m-b-15"
            size="medium"
            label={t(`inventory.status.${status?.value}`)}
          />
          {status?.value !== 'draft' && (
            <Message
              className="m-b-15"
              content={`${t('inventory.readOnlyInfo.base')} ${t(`inventory.readOnlyInfo.${status?.value}`)}`}
              variant="info"
            />
          )}
          <h2 className={utils.cn([styles.title, 'm-b-25'])}>
            {t('inventory.crud.menu.general')}
          </h2>
        </>
        )}
        {status?.value !== 'draft' && (
          <Message
            className="m-b-15"
            content={`${t('inventory.readOnlyInfo.base')} ${t(`inventory.readOnlyInfo.${status?.value}`)}`}
            variant="info"
          />
        )}
        <FormInfoRequired />
        <DateInput
          id="date"
          name="date"
          className="m-t-25"
          label={t('inventory.crud.general.date')}
          value={formik.values.date ? new Date(formik.values.date) : null}
          onChange={(date) => handleChange('date', date)}
          onBlur={formik.handleBlur}
          error={displayError(t, formik, 'date')}
          valid={isFieldValid(formik, 'date')}
          disabled={isReadOnly}
        />
        {!isReadOnly && (
          <SelectInput
            id="propertyUid"
            name="propertyUid"
            className="m-t-25"
            label={t('inventory.crud.general.property')}
            options={propertyOptions}
            error={displayError(t, formik, 'propertyUid')}
            onChange={handlePropertyChange}
            onBlur={formik.handleBlur}
            value={propertyOptions.find((item) => item.value === formik.values.propertyUid) || null}
            onInputChange={(value) => handleSearchProperty(value)}
            isLoading={propertiesListFetching}
            disabled={isReadOnly}
          />
        )}
        {formik.values.propertyUid && (
        <>
          <Message
            className="m-t-25 m-l-15 m-r-15"
            content={(
              <div className="m-l-5">
                <p className={styles.propertyName}>{formik.values.property?.name}</p>
                <p>{formatAddress(formik.values.property?.address)}</p>
              </div>
            )}
            variant="info"
            icon="house-2"
          />
          <SelectInput
            id="leaseUid"
            name="leaseUid"
            className="m-t-25"
            label={t('inventory.crud.general.lease')}
            options={leaseOptions}
            error={displayError(t, formik, 'leaseUid')}
            onChange={handleLeaseChange}
            onBlur={formik.handleBlur}
            value={leaseValue}
            onInputChange={(value) => handleSearchLease(value)}
            isLoading={leasesListFetching}
            disabled={isReadOnly}
          />
        </>
        )}
      </form>
      {isMobile && formik.values.propertyUid && (
      <>
        <InventoryMeters />
        <InventoryKeys />
        <InventoryRooms />
        <InventorySigners />
      </>
      )}
    </>
  );
}

export default InventoryGeneral;
