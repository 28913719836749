/* eslint-disable max-len */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { padStart } from 'lodash';
import { centsToEuro } from 'ui-library-unlocker/src/utils/money';

// Components
import {
  Picto,
  utils,
  SelectInput,
  TextInput,
  DateInput,
  ToggleInput,
  WysiwygInput,
  Table,
  Message,
} from 'ui-library-unlocker';
import CopyBtn from '../../../components/atoms/CopyBtn/CopyBtn';
import MaskedField from '../../../components/atoms/MaskedField/MaskedField';
import Tooltip from '../../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../../components/atoms/TableOptions/TableOptions';

// Services
import { getLeaseIBAN } from '../../../services/lease';

// Hooks
import { useAppContext } from '../../../store/context';
import useRoles from '../../../hooks/useRoles';

// Utils
import { formatAddress } from '../../../utils/properties';
import { getDateValue } from '../../../utils/dates';

// Styles
import styles from './LeaseGeneralData.module.scss';

// Constants
import { LEASE_STATUS } from '../../../utils/constants';

function LeaseGeneralData({ lease, error, bankInfo }) {
  const { t } = useTranslation();
  const { context: { uiBuilders, user } } = useAppContext();
  const { isUserLessor } = useRoles();

  const [tooltipOpen, setTooltipOpen] = useState(null);

  const {
    propertyUid,
    propertyName,
    propertyAddress,
    type,
    usage,
    rent,
    charges,
    paymentFrequency,
    activityPractised,
    chargesRegularisationFrequency,
    startDate,
    duration,
    rentPaymentDay,
    rentAdjustmentFrequency,
    depositAmount,
    terminationFrequency,
    rentSubjectToVat,
    additionalMarkings,
    lastRentAmount,
    lastRentDate,
    lastRentAdjustmentDate,
    uid,
    status,
  } = lease ?? {};

  const {
    iban,
    bic,
    paymentMethod,
    tenantBankingIdentity,
  } = bankInfo ?? {};

  const ibanMutation = useMutation({
    mutationFn: getLeaseIBAN,
    onSuccess: () => {
      utils.toast.info(t('lease.table.ibanExportSuccess'));
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const getLeaseUiBuilderOptions = useCallback((typeKey) => {
    if (!uiBuilders) return [];
    return Object.entries(uiBuilders?.['/lease/ui']?.[typeKey]).map(([key, value]) => ({
      value: key,
      label: value,
    }));
  }, [uiBuilders]);

  const leaseTypesOptions = useMemo(() => getLeaseUiBuilderOptions('leaseTypes'), [getLeaseUiBuilderOptions]);

  const leaseUsagesOptions = useMemo(() => getLeaseUiBuilderOptions('leaseUsages'), [getLeaseUiBuilderOptions]);

  const paymentFrequencyOptions = useMemo(() => getLeaseUiBuilderOptions('leasePaymentFrequencies'), [getLeaseUiBuilderOptions]);

  const startDateValue = useMemo(() => getDateValue(startDate), [getDateValue, startDate]);

  const lastRentDateValue = useMemo(() => getDateValue(lastRentDate), [getDateValue, lastRentDate]);

  const lastRentAdjustmentDateValue = useMemo(() => getDateValue(lastRentAdjustmentDate), [getDateValue, lastRentAdjustmentDate]);

  const regularisationFrequencyOptions = useMemo(
    () => [1, 2, 3].map((frequency) => ({
      label: t('lease.add.form.general.fields.chargesRegularisationFrequencySuffix', {
        count: frequency,
      }),
      value: frequency,
    })),
    [],
  );

  const durationOptions = useMemo(() => {
    const getLabel = (dur) => t('lease.add.form.general.fields.chargesRegularisationFrequencySuffix', {
      count: dur,
    });

    if (!type) return [];
    if (type === 'furnished') {
      // Furnished lease
      return [1].map((dur) => ({
        label: getLabel(dur),
        value: dur,
      }));
    }
    if (type === 'commercial') {
      // Commercial lease
      return [3, 6, 9].map((dur) => ({
        label: getLabel(dur),
        value: dur,
      }));
    }
    // Empty lease
    return [3, 6].map((dur) => ({
      label: getLabel(dur),
      value: dur,
    }));
  }, [type]);

  const data = useMemo(() => [{
    propertyUid,
    propertyName,
    propertyAddress,
    iban,
    bic,
    paymentMethod,
    tenantBankingIdentity,
    uid,
    status,
  }], [lease, bankInfo]);

  const columns = useMemo(() => ([
    {
      header: t('lease.generalInfo.property'),
      accessorKey: 'propertyName',
      size: 300,
      enableSorting: false,
      cell: ({ row: { original: { propertyName: pName, propertyUid: pUid, propertyAddress: pAddress } } }) => (
        <div className={styles.multipleLineColumn}>
          <Link
            to={`/${isUserLessor ? 'property' : 'rechercher'}/${pUid}`}
            target="_blank"
          >
            {pName}
            <Picto icon="export-link" width={10} color="var(--color-secondary)" className={styles.externalLink} />
          </Link>
          <span>{formatAddress(pAddress)}</span>
        </div>
      ),
    },
    {
      header: t('lease.generalInfo.paymentMethod'),
      accessorKey: 'paymentMethod',
      size: 200,
      enableSorting: false,
      cell: ({ row: { original: { paymentMethod: payMeth } } }) => (
        <div className={styles.tableName}>
          {t(`global.paymentMethods.${payMeth}`)}
        </div>
      ),
    },
    {
      header: t('lease.generalInfo.iban'),
      accessorKey: 'iban',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <div className={styles.tableName}>
          <MaskedField
            value={original?.iban}
            className={styles.maskedIban}
            formatValue={(val) => val.replace(/(.{4})/g, '$1 ')}
          />
          <CopyBtn
            textToCopy={original?.iban}
            htmlTitle={t('global.copy')}
            tooltipText={t('global.copied')}
            size={18}
          />
        </div>
      ),
    },
    {
      header: t('lease.generalInfo.bic'),
      accessorKey: 'bic',
      size: 150,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <div className={styles.tableName}>
          {original?.bic}
          &nbsp;
          <CopyBtn
            textToCopy={original?.bic}
            htmlTitle={t('global.copy')}
            tooltipText={t('global.copied')}
            size={18}
          />
        </div>
      ),
    },
    {
      header: t('lease.generalInfo.tenantIban'),
      accessorKey: 'tenantBankingIdentity',
      size: 300,
      enableSorting: false,
      cell: ({ row: { original: { tenantBankingIdentity: tbi } } }) => {
        if (!tbi) {
          return (
            <div className={styles.tableName}>
              -
            </div>
          );
        }
        const tenantIsUser = tbi.owner === user?.username;
        const bankIdIban = tbi.iban;
        return (
          <div className={styles.multipleLineColumn}>
            {tenantIsUser ? (
              <Link
                // details page when created
                // to={`/banking-identities/${tbi.uid}`}
                to="/banking-identities"
                target="_blank"
              >
                {tbi.label}
                <Picto icon="export-link" width={10} color="var(--color-secondary)" className={styles.externalLink} />
              </Link>
            ) : (
              <span>{`${tbi.ownerFirstName} ${tbi.ownerFirstName}`}</span>
            )}
            <div className={styles.tableName}>
              {tbi.isRedacted ? (
                <span className={styles.maskedIban}>
                  {bankIdIban
                    ?.replace(/\*/g, '•')
                    ?.replace(/(.{4})/g, '$1 ')}
                </span>
              ) : (
                <>
                  <MaskedField
                    value={bankIdIban}
                    className={styles.maskedIban}
                    formatValue={(val) => val.replace(/(.{4})/g, '$1 ')}
                  />
                  <CopyBtn
                    textToCopy={bankIdIban}
                    htmlTitle={t('global.copy')}
                    tooltipText={t('global.copied')}
                    size={18}
                  />
                </>
              )}
            </div>
          </div>
        );
      },
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 50,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          {row?.original?.status === LEASE_STATUS.SIGNED && (
          <>
            <Picto
              id={`more-option-lease-${row?.original?.uid}`}
              icon="more"
              width={24}
              onClick={(e) => {
                e.stopPropagation();
                setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
              }}
              color="var(--color-secondary)"
            />
            <Tooltip
              isOpen={tooltipOpen === row?.original?.uid}
              anchorId={`more-option-lease-${row?.original?.uid}`}
              place="bottom"
              type="dark"
              effect="solid"
            >
              <TableOptions
                options={[
                  {
                    id: 'iban',
                    label: t('global.listOptions.downloadIBAN'),
                    icon: <Picto icon="import" width={24} color="var(--color-primary)" />,
                    onHoverIcon: <Picto icon="import" width={24} color="var(--color-white)" />,
                    onClick: (e) => {
                      e.stopPropagation();
                      setTooltipOpen(null);
                      ibanMutation.mutate(row?.original?.uid);
                    },
                  },
                ]}
              />
            </Tooltip>
          </>
          )}
        </div>
      ),
    },
  ]), [t, isUserLessor, user, ibanMutation, tooltipOpen]);

  if (error) {
    return (
      <Message
        variant="error"
        className="m-t-50"
        content={t('global.form.errors.global')}
      />
    );
  }

  if (!lease || !bankInfo) {
    return (
      <Picto className="m-t-50" icon="loading" width={30} color="var(--color-secondary)" />
    );
  }

  return (
    <div className="m-t-50">
      <Table
        fullWidth
        columns={columns}
        data={data}
      />

      <div className={utils.cn(['m-t-55', 'm-b-25', styles.inputGroup])}>
        <div className={styles.largeInput}>
          <SelectInput
            id="type"
            name="type"
            label={t('lease.add.form.general.fields.type')}
            value={leaseTypesOptions.find((cur) => cur.value === type)}
            disabled
          />
        </div>
        <div className={styles.largeInput}>
          <SelectInput
            id="usage"
            name="usage"
            label={t('lease.add.form.general.fields.usage')}
            value={leaseUsagesOptions.find((cur) => cur.value === usage)}
            disabled
          />
        </div>
        {type === 'commercial' && (
          <div className={styles.largeInput}>
            <TextInput
              id="activityPractised"
              name="activityPractised"
              label={t('lease.add.form.general.fields.activityPractised')}
              value={activityPractised}
              disabled
            />
          </div>
        )}
      </div>

      <hr className={styles.separator} />

      <div className={utils.cn(['m-t-25', 'm-b-25', styles.inputGroup])}>
        <div className={styles.largeInput}>
          <DateInput
            id="startDate"
            name="startDate"
            label={t('lease.add.form.general.fields.startDate')}
            value={startDateValue}
            disabled
          />
        </div>
        <div className={styles.largeInput}>
          <SelectInput
            id="duration"
            name="duration"
            label={t('lease.add.form.general.fields.duration')}
            value={durationOptions.find((cur) => cur.value === duration)}
            disabled
          />
        </div>
        <div className={styles.largeInput}>
          <TextInput
            id="rentPaymentDay"
            name="rentPaymentDay"
            label={t('lease.add.form.general.fields.rentPaymentDay')}
            value={t('lease.add.form.general.fields.rentPaymentDayFormat', { date: padStart(rentPaymentDay ?? 0, 2, '0') })}
            disabled
          />
        </div>
      </div>

      <hr className={styles.separator} />

      <div className={utils.cn(['m-t-25', styles.inputGroup])}>
        <div className={styles.largeInput}>
          <TextInput
            id="rent"
            name="rent"
            label={t('lease.add.form.general.fields.rent')}
            value={`${centsToEuro(rent)} €`}
            disabled
          />
        </div>
        <div className={styles.largeInput}>
          <TextInput
            id="charges"
            name="charges"
            label={t('lease.add.form.general.fields.charges')}
            value={`${centsToEuro(charges)} €`}
            disabled
          />
        </div>
        <div className={styles.largeInput}>
          <TextInput
            id="depositAmount"
            name="depositAmount"
            label={t('lease.add.form.general.fields.depositAmount')}
            value={`${centsToEuro(depositAmount)} €`}
            disabled
          />
        </div>
      </div>

      <div className={utils.cn(['m-t-25', styles.inputGroup])}>
        <div className={styles.largeInput}>
          <SelectInput
            id="paymentFrequency"
            name="paymentFrequency"
            label={t('lease.add.form.general.fields.paymentFrequency')}
            value={paymentFrequencyOptions.find((cur) => cur.value === paymentFrequency)}
            disabled
          />
        </div>
        <div className={styles.largeInput}>
          <SelectInput
            id="chargesRegularisationFrequency"
            name="chargesRegularisationFrequency"
            label={t('lease.add.form.general.fields.chargesRegularisationFrequency')}
            value={regularisationFrequencyOptions.find((cur) => cur.value === chargesRegularisationFrequency)}
            disabled
          />
        </div>
        <div className={styles.largeInput}>
          <SelectInput
            id="rentAdjustmentFrequency"
            name="rentAdjustmentFrequency"
            label={t('lease.add.form.general.fields.rentAdjustmentFrequency')}
            value={regularisationFrequencyOptions.find((cur) => cur.value === rentAdjustmentFrequency)}
            disabled
          />
        </div>
        {type === 'commercial' && (
          <div className={styles.largeInput}>
            <SelectInput
              id="terminationFrequency"
              name="terminationFrequency"
              label={t('lease.add.form.general.fields.terminationFrequency')}
              value={durationOptions.find((cur) => cur.value === terminationFrequency)}
              disabled
            />
          </div>
        )}
      </div>

      {type === 'commercial' && (
        <div className={utils.cn(['m-t-25', styles.inputGroup])}>
          <div className={utils.cn(['m-l-15', styles.largeInput])}>
            <ToggleInput
              id="rentSubjectToVat"
              name="rentSubjectToVat"
              label={t('lease.add.form.general.fields.rentSubjectToVat')}
              checked={!!rentSubjectToVat}
              disabled
            />
          </div>
        </div>
      )}

      <hr className={utils.cn(['m-t-25', styles.separator])} />

      <div className={utils.cn(['m-t-25', styles.inputGroup])}>
        <div className={styles.fullWidthInput}>
          <WysiwygInput
            label={t('lease.add.form.general.fields.additionalMarkings')}
            defaultValue={additionalMarkings}
            disabled
          />
        </div>
      </div>

      <h2 className="m-t-40 m-b-30">{t('lease.add.form.tenseArea.title')}</h2>

      <div className={utils.cn(['m-t-25', styles.inputGroup])}>
        <div className={styles.largeInput}>
          <TextInput
            id="lastRentAmount"
            name="lastRentAmount"
            label={t('lease.add.form.tenseArea.fields.lastRentAmount')}
            value={`${centsToEuro(lastRentAmount)} €`}
            disabled
          />
        </div>
        <div className={styles.largeInput}>
          <DateInput
            id="lastRentDate"
            name="lastRentDate"
            label={t('lease.add.form.tenseArea.fields.lastRentDate')}
            value={lastRentDateValue}
            disabled
          />
        </div>
        <div className={styles.largeInput}>
          <DateInput
            id="lastRentAdjustmentDate"
            name="lastRentAdjustmentDate"
            label={t('lease.add.form.tenseArea.fields.lastRentAdjustmentDate')}
            value={lastRentAdjustmentDateValue}
            disabled
          />
        </div>
      </div>
    </div>
  );
}

LeaseGeneralData.propTypes = {
  lease: PropTypes.shape(),
  bankInfo: PropTypes.shape(),
  error: PropTypes.bool,
};

LeaseGeneralData.defaultProps = {
  lease: null,
  bankInfo: null,
  error: false,
};

export default LeaseGeneralData;
