/* eslint-disable import/no-unresolved */
import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// LightBox
import { Lightbox } from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Download from 'yet-another-react-lightbox/plugins/download';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/plugins/counter.css';

// Components
import {
  utils,
} from 'ui-library-unlocker';
import UploadedImage from '../../molecules/UploadedImage/UploadedImage';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

// Services
import { deleteCompanyPicture } from '../../../services/company';

// Utils
import { showModal, hideModal } from '../../../utils/modal';

// Styles
import styles from './ImageList.module.scss';

function ImageList({
  className,
  images,
  idSupplement,
  entityUID,
  entityType,
  refetchImages,
  isReadOnly,
}) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [docSelected, setDocSelected] = useState(null);

  const DELETE_CONFIRM_MODAL_ID = useMemo(() => `image-delete-confirm-${idSupplement}`, [idSupplement]);

  const slides = useMemo(() => {
    if (images) {
      return images.map((image) => ({
        src: typeof image === 'string' ? image : (image.lightboxUrl || image.url),
        alt: 'uploaded image',
      }));
    }
    return [];
  }, [images]);

  const calculatedIndex = useMemo(() => {
    if (slideIndex >= images.length) return images.length - 1 || 0;
    return slideIndex;
  }, [images, slideIndex]);

  const deleteMethod = useMemo(() => {
    if (entityType === 'company') {
      return deleteCompanyPicture;
    }
    return null;
  }, [entityType]);

  const documentDeleteMutation = useMutation({
    mutationFn: () => deleteMethod(entityUID, docSelected),
    onSuccess: () => {
      refetchImages();
      hideModal(DELETE_CONFIRM_MODAL_ID);
      utils.toast.success(t('global.documents.delete.success'));
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default: {
            utils.toast.error(t('global.form.errors.global'));
            break;
          }
        }
      }
    },
  });

  const showDeleteConfirmModal = useCallback((image) => {
    setDocSelected(image?.uid);
    showModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const cancelDelete = useCallback(() => {
    setDocSelected(null);
    hideModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const handleDelete = useCallback(() => {
    documentDeleteMutation.mutate(docSelected);
  }, [docSelected, documentDeleteMutation]);

  const handleOpenLightbox = useCallback((index) => {
    setSlideIndex(index);
    setOpen(true);
  }, []);

  const handleCloseLightbox = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <div className={utils.cn([styles.imageList, className])}>
        {images.map((image, index) => (
          <UploadedImage
            key={typeof image === 'string' ? image : image.uid}
            src={typeof image === 'string' ? image : image.url}
            onViewClick={() => handleOpenLightbox(index)}
            onDeleteClick={() => showDeleteConfirmModal(image)}
            isReadOnly={isReadOnly}
          />
        ))}
      </div>
      <Lightbox
        open={open}
        close={handleCloseLightbox}
        index={calculatedIndex}
        plugins={[Thumbnails, Counter, Zoom, Download]}
        slides={slides}
        carousel={{
          finite: true,
        }}
        styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .8)' } }}
        controller={{
          closeOnBackdropClick: true,
        }}
      />
      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={handleDelete}
        onCancel={cancelDelete}
        loading={documentDeleteMutation.isLoading}
      />
    </>
  );
}

ImageList.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      uid: PropTypes.string,
      url: PropTypes.string,
      lightboxUrl: PropTypes.string,
    }),
  ])),
  idSupplement: PropTypes.string,
  entityUID: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  refetchImages: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

ImageList.defaultProps = {
  className: '',
  images: [],
  idSupplement: '',
  isReadOnly: false,
};

export default ImageList;
