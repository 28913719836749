import React, { useMemo, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import TagManager from 'react-gtm-module';

// Components
import {
  SelectInput,
  TextAreaInput,
  Button,
  Thumbnail,
  utils,
} from 'ui-library-unlocker';
import Modal from '../../components/molecules/Modal/Modal';
import FormInfoRequired from '../../components/atoms/FormInfoRequired/FormInfoRequired';
import FileInput from '../../components/molecules/FileInput/FileInput';
import ImageModal from '../../components/molecules/ImageModal/ImageModal';

// Services
import { getOwners } from '../../services/owner';
import { getTenants } from '../../services/tenant';
import { getManagers } from '../../services/person';
import { getProperties } from '../../services/property';
import { createConversation } from '../../services/messages';
import {
  getOwnersFromAdmin,
  getUsersFromAdmin,
  getPropertiesFromAdmin,
} from '../../services/admin';

// Hooks
import { useAppContext } from '../../store/context';
import useRoles from '../../hooks/useRoles';
import useFileUpload from '../../hooks/useFileUpload';

// Utils
import { showModal, hideModal } from '../../utils/modal';
import { displayError, isFieldValid } from '../../utils/forms/form';
import newMessageSchema, { newMessageInitialValues } from '../../utils/forms/newMessageSchema';

// Constants
import { ENROLMENT_STATUS } from '../../utils/constants';

import styles from './Messages.module.scss';

function NewMessageModal({ modalId, onConversationCreated }) {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const { context: { user, uiBuilders } } = useAppContext();
  const [{ uploadFiles }] = useFileUpload();

  const [files, setFiles] = useState([]);
  const [currentPictureOpened, setCurrentPictureOpened] = useState(null);
  const [recipientQuery, setRecipientQuery] = useState('');
  const [propertyQuery, setPropertyQuery] = useState('');

  const { isUserRealEstateManager, isUserAdmin } = useRoles();

  const unlockerSupportUid = 'unlocker-support';

  const pictureDisplayModalId = 'picture-display-modal';

  const userType = useMemo(() => {
    if (isUserAdmin) return 'admin';
    if (isUserRealEstateManager) return 'manager';
    return 'owner';
  }, [isUserAdmin, isUserRealEstateManager]);

  const formik = useFormik({
    initialValues: newMessageInitialValues,
    validationSchema: newMessageSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const handleProgression = (/* fileIndex, document */) => {
        // TODO: implement a cool file upload progression interface
        // either a bar or a checkmark over the thumbnail
      };

      formik.setSubmitting(true);

      try {
        const res = await uploadFiles(files, handleProgression);
        const documentUids = res?.map((doc) => doc.uid);
        const { message, ...rest } = values;

        const payload = rest;

        const forAdmin = payload.recipientUid === unlockerSupportUid;

        if (forAdmin) {
          payload.isSupport = true;
          payload.recipientUid = undefined;
        }

        TagManager.dataLayer({
          dataLayer: {
            event: 'new_message_sent',
            userType,
            support: forAdmin,
          },
        });

        newMessageMutation.mutate({
          ...payload,
          message: {
            createdAt: (new Date().getTime() / 1000).toFixed(0).toString(),
            content: message,
            documentUids,
          },
        });
      } catch (error) {
        formik.setSubmitting(false);
      }
    },
  });

  const newMessageMutation = useMutation({
    mutationFn: createConversation,
    onSuccess: ({ response, status, data }) => {
      const s = status || response?.status;
      switch (s) {
        case 201:
          utils.toast.success(t('messages.newMessageForm.success'));
          formik?.resetForm();
          setFiles([]);
          hideModal(modalId);
          if (onConversationCreated) onConversationCreated(data.uid);
          break;
        default:
          break;
      }
      formik.setSubmitting(false);
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            formik.setErrors(err?.response?.data?.errors);
            break;
          }
          case 500: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            break;
        }
      }
      formik.setSubmitting(false);
    },
  });

  const {
    data: ownerListData,
    isFetching: isOwnerListFetching,
  } = useQuery({
    queryKey: ['owner-list', isUserAdmin, recipientQuery],
    queryFn: () => (isUserAdmin ? getOwnersFromAdmin({
      page: 1,
      itemsPerPage: 1000,
      filters: {
        // Excludes property owners that are not yet onboarded
        onboardingStatusNot: [ENROLMENT_STATUS.PENDING],
        search: [recipientQuery],
      },
    }) : getOwners({
      page: 1,
      itemsPerPage: 1000,
      filters: {
        search: [recipientQuery],
      },
    })),
    keepPreviousData: true,
  });

  const {
    data: managerListData,
    isFetching: isManagerListFetching,
  } = useQuery({
    queryKey: ['managers-list', isUserAdmin, recipientQuery],
    queryFn: () => (isUserAdmin ? getUsersFromAdmin({
      page: 1,
      itemsPerPage: 1000,
      filters: {
        // Excludes managers that are not yet onboarded
        onboardingStatusNot: [ENROLMENT_STATUS.PENDING],
        role: ['ROLE_LESSOR'],
        search: [recipientQuery],
      },
    }) : getManagers({
      page: 1,
      itemsPerPage: 1000,
      filters: {
        search: [recipientQuery],
      },
    })),
    keepPreviousData: true,
  });

  const {
    data: tenantListData,
    isFetching: isTenantListFetching,
  } = useQuery({
    queryKey: ['tenant-list', isUserAdmin, recipientQuery],
    queryFn: () => (isUserAdmin ? getUsersFromAdmin({
      page: 1,
      itemsPerPage: 1000,
      filters: {
        // Excludes tenants that are not yet onboarded
        onboardingStatusNot: [ENROLMENT_STATUS.PENDING],
        role: ['ROLE_USER'],
        search: [recipientQuery],
      },
    }) : getTenants({
      page: 1,
      itemsPerPage: 1000,
      filters: {
        search: [recipientQuery],
      },
    })),
    keepPreviousData: true,
  });

  const handleSearchRecipient = useCallback(debounce((value) => {
    setRecipientQuery(value);
  }, 500), [setRecipientQuery]);

  const {
    data: propertyListData,
    isFetching: isPropertyListFetching,
  } = useQuery({
    queryKey: ['property-list', isUserAdmin, propertyQuery],
    queryFn: () => (isUserAdmin ? getPropertiesFromAdmin({
      page: 1,
      itemsPerPage: 1000,
      filters: {
        search: [propertyQuery],
      },
    }) : getProperties({
      page: 1,
      itemsPerPage: 1000,
      filters: {
        search: [propertyQuery],
      },
    })),
    keepPreviousData: true,
  });

  const handleSearchProperty = useCallback(debounce((value) => {
    setPropertyQuery(value);
  }, 500), [setPropertyQuery]);

  const recipientOptions = useMemo(() => {
    let options = [];
    if (!isUserAdmin) {
      options = [{
        label: t('global.admins'),
        options: [{
          value: unlockerSupportUid,
          label: t('global.unlockerSupport'),
        }],
      }];
    }
    if (ownerListData) {
      const realEstateProOptions = ownerListData?.data?.collection.map((owner) => ({
        value: owner.uid,
        label: `${owner.firstName} ${owner.lastName}`,
      }));
      options = [...options, {
        label: t('global.owners'),
        options: realEstateProOptions,
      }];
    }
    if (managerListData) {
      const managerList = isUserAdmin
        ? managerListData?.data?.collection
          ?.filter((manager) => manager.companies?.find((company) => company.isRealEstateAgency))
        : managerListData?.data?.collection;
      const managerOptions = managerList.map((manager) => ({
        value: manager.uid,
        label: manager.legalName || `${manager.firstName} ${manager.lastName}`,
      }));
      options = [...options, {
        label: t('global.managers'),
        options: managerOptions,
      }];
    }
    if (tenantListData) {
      const tenantOptions = tenantListData?.data?.collection.map((tenant) => ({
        value: tenant.uid,
        label: `${tenant.firstName} ${tenant.lastName}`,
      }));
      options = [...options, {
        label: t('global.tenants'),
        options: tenantOptions,
      }];
    }
    return options;
  }, [ownerListData, tenantListData, managerListData, isUserRealEstateManager, isUserAdmin, t]);

  const propertyOptions = useMemo(() => {
    if (propertyListData) {
      return propertyListData?.data?.collection.map((property) => ({
        value: property.uid,
        label: property.name,
      }));
    }
    return [];
  }, [propertyListData]);

  const subjectOptions = useMemo(() => {
    if (uiBuilders?.['/messaging/ui']) {
      const { conversationSubject } = uiBuilders['/messaging/ui'];
      return Object.keys(conversationSubject).map((subjectKey) => ({
        value: subjectKey,
        label: conversationSubject[subjectKey],
      }));
    }
    return [];
  }, [uiBuilders]);

  const handleFileSelection = (e) => {
    const f = e?.target?.files[0];

    if (f?.size > 10000000) {
      utils.toast.error(t('global.form.errors.file.tooLarge'));
      return;
    }
    if (f) {
      const setFileFields = (image = null) => {
        const dim = !image
          ? {}
          : {
            width: image.naturalWidth,
            height: image.naturalHeight,
          };
        setFiles([...files, {
          file: f,
          src: image ? image.src : null,
          id: uuidv4(),
          data: {
            customName: f?.name,
            filename: f?.name,
            type: 'userDocuments',
            userUid: user?.username,
            metadata: {
              documentSize: f?.size,
              extension: f?.type?.split('/')[1],
              ...dim,
            },
          },
        }]);

        if (image) image.removeEventListener('load', setFileFields);
      };

      if (f?.type === 'application/pdf') {
        // PDF
        const reader = new FileReader();
        reader.onload = () => setFileFields(null);
        reader.readAsText(f);
      } else {
        // IMAGE
        const image = new Image();
        image.src = URL.createObjectURL(f);
        image.addEventListener('load', () => setFileFields(image));
      }
    }
  };

  const findRecipientValue = (recipientUid) => {
    let value = null;
    recipientOptions.find((recipientGroup) => {
      const { options } = recipientGroup;
      const found = options.find((option) => option.value === recipientUid);
      if (found) value = found;
      return found;
    });
    return value;
  };

  return (
    <>
      <Modal
        className={styles.modal}
        id={modalId}
        title={t('messages.newMessageForm.title')}
        size="large"
        onClose={() => {
          hideModal(modalId);
        }}
        noWrap
      >
        <form className={styles.newMessageForm} onSubmit={formik.handleSubmit}>
          <SelectInput
            id="recipientUid"
            name="recipientUid"
            label={t('messages.newMessageForm.recipient')}
            options={recipientOptions}
            error={displayError(t, formik, 'recipientUid')}
            valid={isFieldValid(formik, 'recipientUid', null, newMessageInitialValues?.recipientUid)}
            onChange={(recipient) => formik.setFieldValue('recipientUid', recipient.value)}
            onBlur={formik.handleBlur}
            value={findRecipientValue(formik.values.recipientUid)}
            onInputChange={(value) => handleSearchRecipient(value)}
            isLoading={isManagerListFetching || isOwnerListFetching || isTenantListFetching}
          />
          <SelectInput
            className={utils.cn(['m-t-25'])}
            id="propertyUid"
            name="propertyUid"
            label={t('messages.newMessageForm.property')}
            options={propertyOptions}
            error={displayError(t, formik, 'propertyUid')}
            valid={isFieldValid(formik, 'propertyUid', null, newMessageInitialValues?.propertyUid)}
            onChange={(property) => formik.setFieldValue('propertyUid', property.value)}
            onBlur={formik.handleBlur}
            value={propertyOptions.find((propertyUid) => propertyUid.value === formik.values.propertyUid) || null}
            onInputChange={(value) => handleSearchProperty(value)}
            isLoading={isPropertyListFetching}
          />
          <SelectInput
            className={utils.cn(['m-t-25'])}
            id="subject"
            name="subject"
            label={t('messages.newMessageForm.subject')}
            options={subjectOptions}
            error={displayError(t, formik, 'subject')}
            valid={isFieldValid(formik, 'subject', null, newMessageInitialValues?.subject)}
            onChange={(property) => formik.setFieldValue('subject', property.value)}
            onBlur={formik.handleBlur}
            value={subjectOptions.find((subject) => subject.value === formik.values.subject) || null}
          />
          <TextAreaInput
            id="message"
            name="message"
            className={utils.cn(['m-t-25'])}
            label={t('messages.newMessageForm.message')}
            onChange={formik.handleChange}
            value={formik.values.message}
          />
          <p className="text-left m-t-30 p-1-700">
            {t('messages.newMessageForm.addFiles')}
          </p>
          <FormInfoRequired className="m-t-25 text-left p-2-500" content={t('messages.newMessageForm.addFilesInfo')} />
          <div className={styles.submitFileBlock}>
            <FileInput
              className="m-t-25"
              id="profile-file"
              name="profile-file"
              label={t('messages.newMessageForm.addFilesSubmit')}
              accept="image/jpg,image/png,image/jpeg,application/pdf"
              value={null}
              onChange={handleFileSelection}
            />
          </div>
          {files.map((file) => (
            <div key={file.src} className={styles.fileBlock}>
              <Thumbnail
                src={file.src}
                alt={file.file.name}
                isDeletable
                original={file.src}
                file={file.file}
                fileNameHeader="Titre du fichier"
                onClick={() => {
                  setCurrentPictureOpened(file);
                  showModal(pictureDisplayModalId);
                }}
                onDeleteClick={() => {
                  setFiles(files.filter((f) => f.id !== file.id));
                }}
              />
            </div>
          ))}
          <hr className={utils.cn(['m-t-20 m-b-20', styles.separator])} />
          <Button
            type="submit"
            className={styles.submitBlock}
            variant="primary"
            size="large"
            label={t('messages.newMessageForm.submit')}
            loading={formik.isSubmitting}
          />
        </form>
      </Modal>
      {createPortal(
        <ImageModal
          className={styles.imageModal}
          id={pictureDisplayModalId}
          onClose={() => {
            hideModal(pictureDisplayModalId);
            setCurrentPictureOpened(null);
          }}
        >
          {/* ORIGINAL */}
          {currentPictureOpened
            ? (
              <img
                src={currentPictureOpened?.src}
                alt={currentPictureOpened?.file?.name}
              />
            )
            : null}
        </ImageModal>,
        document.querySelector('body'),
      )}
    </>
  );
}

NewMessageModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  onConversationCreated: PropTypes.func,
};

NewMessageModal.defaultProps = {
  onConversationCreated: () => {},
};

export default NewMessageModal;
